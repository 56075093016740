import { Component, inject, input } from '@angular/core';
import { UserAuthorizationService } from 'src/app/modules/user/user-authorization.service';

import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
    selector: 'app-control-object-secondary-menu-header',
    templateUrl: './control-object-secondary-menu-header.component.html',
    styleUrls: ['./control-object-secondary-menu-header.component.less'],
    imports: [RouterLinkActive, RouterLink]
})
export class ControlObjectSecondaryMenuHeaderComponent {
  authorizationService = inject(UserAuthorizationService);

  readonly resourceId = input<number | undefined>(0);

  readonly urlBase = input<string>('');
}
