import { Component, ElementRef, viewChild, inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalSettings } from 'src/environments/global-settings';
import { CountDownComponent } from '../count-down/count-down.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-idle-session-box',
    templateUrl: './idle-session-box.component.html',
    styleUrls: ['./idle-session-box.component.scss'],
    imports: [CountDownComponent]
})

export class IdleSessionBoxComponent {
  dialogRef = inject(DynamicDialogRef);

  private authService = inject(AuthService);

  readonly btnYes = viewChild<ElementRef>('btnYes');
  timerTimeInMinutes = GlobalSettings.SessionTimer;

  constructor() {
    const dialogRef = this.dialogRef;

    dialogRef.onClose.subscribe(() => {
      this.btnYes()?.nativeElement.focus();
    });
  }

  answerYes(): void {
    this.dialogRef.close('yes');
    this.authService.getLogin();
  }
  answerNo(): void {
    this.dialogRef.close('no');
    this.endSession();
  }
  endSession(): void {
    this.authService.logoutUser();
  }
}