import { Injectable, TemplateRef } from "@angular/core";
import { ConfirmOptions } from "./confirm-options.model";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Injectable()
export class ConfirmState {
  options?: ConfirmOptions;

  modal?: DynamicDialogRef;

  template?: TemplateRef<any>;
}