<div class="input-group">
  <label class="input-group-btn">
    <span class="btn btn-gray upload-button" [ngClass]="isDisabled() ? 'disabled' : ''">
      <fa-icon [icon]="cameraIcon"></fa-icon><input [disabled]="isDisabled()" class="upload-hidden-field" type="file" [accept]="acceptAttr()">
    </span>
  </label>
  <input type="text" [ngClass]="file ? 'file-is-selected' : ''" class="upload-info-field form-control" readonly [value]="file ? file.name : existingFileName() ? existingFileName() : ''">
  @if ((file || existingFileId()) && !isDisabled()) {
    <a (click)="onSelectedFileRemove()" class="btn btn-gray reset-file-input-button" type="button" id="button-addon2"><fa-icon [icon]="deleteIcon"></fa-icon></a>
  }
  @if (file || existingFileId()) {
    <a (click)="downloadFile()" class="btn btn-gray reset-file-input-button" type="button" id="button-addon2"><fa-icon [icon]="downloadIcon"></fa-icon></a>
  }
</div>