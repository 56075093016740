<div class="notification"
  [class.success]="notification().type === 'success'"
  [class.info]="notification().type === 'info'"
  [class.warning]="notification().type === 'warning'"
  [class.error]="notification().type === 'error'"
  (mouseenter)="cancelAutoClose()"
  (mouseleave)="startTimer()"
  >
  <div class="notification-content">
    <p>{{ notification().message }}</p>
  </div>
  <button class="notification-close" (click)="ns.dismiss(notification().id)"></button>
  @if (autoClose) {
    <div class="notification-progress">
      <div class="current-progress" [style.width.%]="width$ | async"></div>
    </div>
  }
</div>
