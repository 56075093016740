import { Component, input, output } from '@angular/core';
import { map, takeWhile, tap, timer } from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-count-down',
    templateUrl: './count-down.component.html',
    styleUrls: ['./count-down.component.scss'],
    imports: [AsyncPipe, DatePipe]
})
export class CountDownComponent {

    readonly timerMinutes = input.required<number>();

    readonly onTimerFinished = output();

    timeRemaining$ = timer(0, 1000).pipe(
      map(n => (this.timerMinutes() * 60 - n) * 1000),
      takeWhile(n => n >= 0),
      tap({
        complete: () => this.onTimerFinished.emit()
      })
    );
}
