import { Component, OnInit, inject } from '@angular/core';
import { GlobalSettings } from 'src/environments/global-settings';
import { HistoryService } from './services/history.service';
import { IdleService } from './services/idle.helper';
import { PageLayoutComponent } from './layout/page-layout/page-layout.component';
import { NotificationsComponent } from './modules/notifications/notifications.component';
import { PrimeNG } from 'primeng/config';
import translation from 'src/assets/i18n/messages.lt.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    imports: [PageLayoutComponent, NotificationsComponent]
})
export class AppComponent implements OnInit {
  readonly historyService = inject(HistoryService);
  private idleService = inject(IdleService);
  private primeNG = inject(PrimeNG);

  ngOnInit(): void {
    this.initialIdleSettings();
    this.primeNG.setTranslation(translation.primeng);
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = GlobalSettings.SessionTimeTillTimer * 60;
    this.idleService
      .startWatching(idleTimeoutInSeconds)
      .subscribe((isTimeOut: boolean) => {
        if (isTimeOut) {
          this.idleService.openDialog();
        }
      });
  }
}
