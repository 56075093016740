import { Component, OnInit, input } from '@angular/core';
import { GeneralDataDisplayItem } from './models/general-data-display-item.model';


@Component({
    selector: 'app-general-data-display',
    templateUrl: './general-data-display.component.html',
    styleUrls: ['./general-data-display.component.less'],
    imports: []
})
export class GeneralDataDisplayComponent implements OnInit {
  readonly items = input<GeneralDataDisplayItem[]>([]);

  readonly title = input<string>('');

  constructor() {}

  ngOnInit(): void {}
}
