import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from './content-wrapper/content-wrapper.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderWrapperComponent } from './header-wrapper/header-wrapper.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PrimaryMenuHeaderComponent } from './primary-menu-header/primary-menu-header.component';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { RouterModule } from '@angular/router';
import { ControlObjectSecondaryMenuHeaderComponent } from './control-object-secondary-menu-header/control-object-secondary-menu-header.component';
import { SharedModule } from '../shared/shared.module';
import { EnvironmentComponent } from './environment/environment.component';
import { UserInfoComponent } from './user-info/user-info.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        ContentWrapperComponent,
        FooterComponent,
        HeaderWrapperComponent,
        PageLayoutComponent,
        PrimaryMenuHeaderComponent,
        ActionMenuComponent,
        ControlObjectSecondaryMenuHeaderComponent,
        EnvironmentComponent,
        UserInfoComponent,
    ],
    exports: [
        ContentWrapperComponent,
        FooterComponent,
        HeaderWrapperComponent,
        PageLayoutComponent,
        PrimaryMenuHeaderComponent,
        ActionMenuComponent,
        ControlObjectSecondaryMenuHeaderComponent,
    ],
    providers: [],
})
export class LayoutModule {}
