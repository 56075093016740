import { Component, OnInit, input, output, inject } from "@angular/core";
import {NotificationService} from "../../services/notification.service";
import {Notification} from "../../types/notification.types";
import {finalize, interval, Observable, of, takeWhile, TimeInterval, timeInterval, timer} from "rxjs";
import {map, takeUntil, tap} from "rxjs/operators";
import { AsyncPipe } from "@angular/common";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.less'],
    imports: [AsyncPipe]
})
export class NotificationComponent implements OnInit {
  ns = inject(NotificationService);

  readonly notification = input.required<Notification>();
  readonly onClose = output<number>();

  public autoClose: boolean = true;
  private shouldAutoClose: boolean = true;
  private autoCloseTime = 4000;
  private updateInterval = 50;

  timer$: Observable<number> = of(0);
  width$: Observable<number> = of(0);
  close$: Observable<boolean> = of(false)

  ngOnInit() {
    this.startTimer()
  }

  startTimer() {
    if (this.autoClose) {
      this.shouldAutoClose = true;
      this.timer$ = timer(this.autoCloseTime)
      this.width$ = timer(0, this.updateInterval).pipe(
        takeUntil(this.timer$),
        map(v => Math.round(v * this.updateInterval/this.autoCloseTime * 100)),
        finalize(() => {
          if (this.shouldAutoClose) {
            this.ns.dismiss(this.notification().id)
          }
        })
      )
    }
  }

  cancelAutoClose() {
    this.shouldAutoClose = false
    this.width$ = of(0)
    this.timer$ = of(0)
  }

}
