<div class="table-top">
  <div class="table-top-left max-full">
    <div class="title">{{ title() }}</div>
  </div>
  <div class="table-top-right">
    <ng-content select="[table-head-right]"></ng-content>
  </div>
</div>
<div class="table-responsive">
  <table class="table-stripes">
    <thead>
      <tr>
        @if (isPlanned) {
          <th>Metai</th>
        }
        @if (isPlanned) {
          <th>Mėnuo</th>
        }
        <th>Tipas</th>
        <th>Rūšis</th>
        <th>Kontrolės objektas</th>
        <th class="text-right">RG</th>
      </tr>
    </thead>
    @if (!(list.isLoading$ | async) && rows.totalCount && rows.totalCount > 0) {
      <tbody>
        @for (row of rows.items; track row.id) {
          <tr>
            @if (isPlanned) {
              <td>{{ row.year }}</td>
            }
            @if (isPlanned) {
              <td>{{ row.month | dateMonthLabel }}</td>
            }
            <td>{{ row.type | inspectionTypeLabel }}</td>
            <td>{{ row.kind | inspectionKindLabel }}</td>
            <td>{{ row.controlObjectName}}</td>
            <td class="text-right">
              <app-authorized-button
                [haveRole]="userAuthService.canDeleteEvent"
                [name]="'Trinti'"
                [style]="'btn btn-gray'"
                (click)="deleteEvent(row)"
                >
              </app-authorized-button>
              <app-authorized-button
                [haveRole]="userAuthService.canEditEvent"
                [name]="'Redaguoti'"
                [style]="'btn btn-gray mx-2'"
                (click)="editEvent(row)"
                >
              </app-authorized-button>
              @if(row.orderId){
                <app-authorized-button
                  [haveRole]="userAuthService.canViewEvent"
                  [name]="rowViewActionButtonTitle()"
                  [style]="'btn btn-gray'"
                  (click)="rowActionClickHandler(row)"
                  >
                </app-authorized-button>
              }
              @else {
              <app-authorized-button
                [haveRole]="userAuthService.canCreateOrderForUserOrganization"
                [name]="rowCreateActionButtonTitle()"
                [style]="'btn btn-gray'"
                (click)="rowActionClickHandler(row)"
                >
              </app-authorized-button>
            }
          </td>
        </tr>
      }
      <tr>
        <td colspan="8" class="no-padding">
          <app-table-paging
            [totalCount]="rows.totalCount"
            [(page)]="list.page"
            [(maxResultCount)]="list.maxResultCount"
          ></app-table-paging>
        </td>
      </tr>
    </tbody>
  }
  @if (!(list.isLoading$ | async) && !rows.totalCount || rows.totalCount === 0) {
    <tbody>
      <tr>
        <td colspan="6" class="text-center">{{ noRecordsTitle() }}</td>
      </tr>
    </tbody>
  }
  @if ((list.isLoading$ | async)) {
    <tbody>
      <tr>
        <td colspan="6">
          <app-spinner></app-spinner>
        </td>
      </tr>
    </tbody>
  }
</table>
</div>
