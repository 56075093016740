<div class="table-top">
  <div class="table-top-left max-full">
    <div class="title">{{ title() }}</div>
  </div>
  <div class="clear"></div>
</div>
<div class="table-responsive">
  <table class="table-stripes">
    <thead>
      <tr>
        <th>Kontrolės objektas</th>
        <th>Adresas</th>
        <th>Pavedimas</th>
        <th>Pavedimo data</th>
        <th>Būsena</th>
        <th>Kontrolės pradžia</th>
        <th class="text-right">Kontrolė</th>
      </tr>
    </thead>
    @if (!(list.isLoading$ | async) && rows.totalCount && rows.totalCount > 0) {
      <tbody>
        @for (row of rows.items; track row.id) {
          <tr>
            <td>{{ row.controlObjectName }}</td>
            <td>{{ row.controlObjectAddress }}</td>
            <td>
              <span class="link" (click)="editEvent(row)">{{ row.orderNumber }}</span>
            </td>
            <td>{{ row.orderDate | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
            <td>{{ row.status | orderStatusLabel}}</td>
            <td>{{ row.inspectionStartFrom | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
            <td class="text-right">
              @if (row.status == orderStatus.Ivykdytas && userAuthService.canViewOrderDetails) {
                @if (canViewInspection(row)) {
                  <button type="button" class="btn btn-light"
                  (click)="openInspection(row)">Peržiūrėti</button>
                }
              }
              <!-- <button class="btn btn-light" *ngIf="row.status == orderStatus.Ivykdytas && userAuthService.canViewOrders" (click)="openInspection(row)">Peržiūrėti</button> -->
            </td>
          </tr>
        }
        <tr>
          <td colspan="8" class="no-padding">
            <app-table-paging
              [totalCount]="rows.totalCount"
              [(page)]="list.page"
              [(maxResultCount)]="list.maxResultCount"
            ></app-table-paging>
          </td>
        </tr>
      </tbody>
    }
    @if (!(list.isLoading$ | async) && !rows.totalCount || rows.totalCount === 0) {
      <tbody>
        <tr>
          <td colspan="8" class="text-center">Pavedimų nėra</td>
        </tr>
      </tbody>
    }
    @if ((list.isLoading$ | async)) {
      <tbody>
        <tr>
          <td colspan="8">
            <app-spinner></app-spinner>
          </td>
        </tr>
      </tbody>
    }
  </table>
</div>
