import { Component, inject } from "@angular/core";
import {NotificationService} from "./services/notification.service";

import { NotificationComponent } from "./components/notification/notification.component";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    imports: [NotificationComponent]
})
export class NotificationsComponent {  ns = inject(NotificationService);



}
