import { Component, ElementRef, HostListener, OnInit, input, model, output, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  faCamera,
  faDownload,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FileDescriptorService } from 'src/app/services/file-descriptor.service';
import { downloadBlob } from '../helpers/file-utils';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: '[app-single-file-upload], app-single-file-upload',
    templateUrl: './single-file-upload.component.html',
    styleUrls: ['./single-file-upload.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SingleFileUploadComponent,
            multi: true,
        },
    ],
    imports: [NgClass, FaIconComponent]
})
export class SingleFileUploadComponent implements ControlValueAccessor, OnInit {
  private host = inject<ElementRef<HTMLInputElement>>(ElementRef);
  private fileService = inject(FileDescriptorService);

  readonly existingFileName = model<string>();

  readonly existingFileId = model<number>();

  readonly acceptAttr = input<string>('image/*');

  cameraIcon = faCamera;

  deleteIcon = faTrash;

  downloadIcon = faDownload;

  private onTouched!: Function;

  private onChanged!: Function;

  public file: File | null = null;

  readonly isDisabled = model(false);

  readonly onRemove = output<number | undefined>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChanged(file);
    this.file = file;
  }

  writeValue(value: null) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  ngOnInit(): void {}

  onSelectedFileRemove() {
    this.onRemove.emit(this.existingFileId());
    this.host.nativeElement.value = '';
    this.file = null;
    this.existingFileId.set(undefined);
    this.existingFileName.set(undefined);
    this.onChanged();
  }

  downloadFile() {
    const existingFileId = this.existingFileId();
    const existingFileName = this.existingFileName();
    if (existingFileId && existingFileName) {
      this.fileService.download(existingFileId, existingFileName);
    } else if (this.file) {
      downloadBlob(this.file, this.file.name);
    }
  }
}
