import { Component, OnInit, input, output, inject } from '@angular/core';
import { ControlObjectOrderService } from 'src/app/modules/control-object/services/control-object-order.service';
import { OrderListItemDto } from 'src/app/modules/order/order-list/models/order-list-item-dto.model';
import { OrderListFilterDto } from 'src/app/modules/order/order-search/models/order-list-filter-dto.model';
import { UserAuthorizationService } from 'src/app/modules/user/user-authorization.service';
import { OrderStatus, OrderStatusPipe } from '../enums/order-status.enum';
import { PagedResultDto } from '../models/paging-sorting';
import { ListService } from '../services/list.service';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, DatePipe } from '@angular/common';
import { TablePagingComponent } from '../table-paging/table-paging.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-orders-list',
    templateUrl: './orders-list.component.html',
    styleUrls: ['./orders-list.component.less'],
    providers: [ListService],
    imports: [FormsModule, TablePagingComponent, SpinnerComponent, AsyncPipe, DatePipe, OrderStatusPipe]
})
export class OrdersListComponent implements OnInit {
  private controlObjectOrderService = inject(ControlObjectOrderService);
  readonly list = inject<ListService<OrderListFilterDto>>(ListService);
  userAuthService = inject(UserAuthorizationService);

  readonly resourceId = input<number>(0);

  readonly rowActionButtonTitle = input<string>('');

  readonly noRecordsTitle = input<string>('');

  readonly title = input<string>('');

  readonly onRowActionButtonClick = output<OrderListItemDto>();

  readonly onCreate = output();

  readonly onOpenInspectionClick = output<any>();

  orderStatus: typeof OrderStatus = OrderStatus;

  rows: PagedResultDto<OrderListItemDto> = {};
  isLoading: boolean = false;

  ngOnInit(): void {
    this.getControlObjectData();
  }

  getControlObjectData(searchDto?: OrderListFilterDto) {
    const controlObjectsStreamCreator = (query: any) => {
      this.isLoading = true;
      return this.controlObjectOrderService.getFilteredList(this.resourceId(), {
        ...query,
        ...searchDto,
      });
    }

    this.list
      .hookToQuery(controlObjectsStreamCreator)
      .subscribe((response) => {
        this.rows = response;
        this.isLoading = false;
      }, () => {
        this.rows = { totalCount: 0, items: [] };
        this.isLoading = false;
      });
  }

  editEvent(row: OrderListItemDto) {
    this.onRowActionButtonClick.emit(row);
  }

  createClickHandler() {
    this.onCreate.emit();
  }

  openInspection(row: OrderListItemDto) {
    this.onOpenInspectionClick.emit(row);
  }

  isOneOfInspectors(inspectionOfficersIds: number[] | undefined): boolean {
    // return false;
    return !!inspectionOfficersIds?.includes(this.userAuthService.userInfo.employeeId);
  }

  loggedUserIsTheSameOrgAsControlObject(orgId: number | undefined): boolean {
    // note maybe check for isActive
    return this.userAuthService.userInfo.clsfOrganizations.some(x => x.clsfOrganizationId == orgId);
  }

  loggedUserIsTheSameOrgAsOrderOrg(orgId: number | undefined): boolean {
    // note maybe check for isActive
    return this.userAuthService.userInfo.clsfOrganizations.some(x => x.clsfOrganizationId == orgId);
  }

  canViewInspection(row: OrderListItemDto): boolean {
  return (this.isOneOfInspectors(row.officerIds) || this.loggedUserIsTheSameOrgAsControlObject(row.controlObjectOrganizationId))
    && (this.userAuthService.canViewInspectionData);
  }
}
