{
  "locale": "lt",
  "translations": {
  },
  "primeng":{
    "accept": "Taip",
    "addRule": "Pridėti taisyklę",
    "am": "AM",
    "apply": "Taikyti",
    "cancel": "Atšaukti",
    "choose": "Pasirinkti",
    "chooseDate": "Pasirinkti datą",
    "chooseMonth": "Pasirinkti mėnesį",
    "chooseYear": "Pasirinkti metus",
    "clear": "Išvalyti",
    "completed": "Įvykdyta",
    "contains": "Turintis",
    "custom": "Tinkintas",
    "dateAfter": "Data vėlesnė nei",
    "dateBefore": "Data ankstesnė nei",
    "dateFormat": "yy-mm-dd",
    "dateIs": "Data yra",
    "dateIsNot": "Data nėra",
    "dayNames": ["Sekmadienis", "Pirmadienis", "Antradienis", "Trečiadienis", "Ketvirtadienis", "Penktadienis", "Šeštadienis"],
    "dayNamesMin": ["S", "P", "A", "T", "K", "Pn", "Š"],
    "dayNamesShort": ["S", "P", "A", "T", "K", "Pn", "Š"],
    "emptyFilterMessage": "Rezultatų nerasta",
    "emptyMessage": "Nėra pasirinkimo",
    "emptySearchMessage": "Įrašų nerasta",
    "emptySelectionMessage": "Nieko nepasirinkta",
    "endsWith": "Baigiasi",
    "equals": "Lygu",
    "fileSizeTypes": ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    "filter": "Filtras",
    "firstDayOfWeek": 1,
    "gt": "Daugiau nei",
    "gte": "Daugiau arba lygu",
    "lt": "Mažiau nei",
    "lte": "Mažiau arba lygu",
    "matchAll": "Atitikti viską",
    "matchAny": "Atitikti bet kurį",
    "medium": "Medium",
    "monthNames": ["Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis", "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"],
    "monthNamesShort": ["Sau", "Vas", "Kov", "Bal", "Geg", "Bir", "Lie", "Rugp", "Rugs", "Spa", "Lap", "Gru"],
    "nextDecade": "Kitas dešimtmetis",
    "nextHour": "Kita valanda",
    "nextMinute": "Kita minutė",
    "nextMonth": "Kitas monuo",
    "nextSecond": "Kita sekundė",
    "nextYear": "Kiti metai",
    "noFilter": "Be filtrų",
    "notContains": "Neturintis",
    "notEquals": "Nelygu",
    "now": "Dabar",
    "passwordPrompt": "Įveskite slaptažodį",
    "pending": "Laukiama",
    "pm": "PM",
    "prevDecade": "Praeitas dešimtmetis",
    "prevHour": "Praeita valanda",
    "prevMinute": "Praeita minutė",
    "prevMonth": "Praeitas monuo",
    "prevSecond": "Praeita sekundė",
    "prevYear": "Praeiti metai",
    "reject": "Ne",
    "removeRule": "Pašalinti taisyklę",
    "searchMessage": "Pateikta {0} rezultatų",
    "selectionMessage": "Pasirinkta {0} pozicijų",
    "showMonthAfterYear": false,
    "startsWith": "Prasideda",
    "strong": "Strong",
    "today": "Šiandien",
    "upload": "Įkelti",
    "weak": "Weak",
    "weekHeader": "Wk",
    "aria": {
        "cancelEdit": "Atšaukti redagavimą",
        "close": "Uždaryti",
        "collapseLabel": "Suskleisti",
        "collapseRow": "Eilutė suskleista",
        "editRow": "Redaguoti eilutę",
        "expandLabel": "Išskleisti",
        "expandRow": "Eilutė išskleista",
        "falseLabel": "False",
        "filterConstraint": "Filtro parametrai",
        "filterOperator": "Filtravimo pasirinkimai",
        "firstPageLabel": "Pirmas puslapis",
        "gridView": "Tinklelio vaizdas",
        "hideFilterMenu": "Slėpti filtro parinktis",
        "jumpToPageDropdownLabel": "Peršokti į pasirinkimą",
        "jumpToPageInputLabel": "Peršokti į įvedimą",
        "lastPageLabel": "Paskutinis puslapis",
        "listView": "Sąrašo vaizdas",
        "moveAllToSource": "Move All to Source",
        "moveAllToTarget": "Move All to Target",
        "moveBottom": "Nuvilkti į apačią",
        "moveDown": "Nuvilkti žemyn",
        "moveToSource": "Move to Source",
        "moveToTarget": "Move to Target",
        "moveTop": "Nuvilkti į viršų",
        "moveUp": "Nuvilkti aukštyn",
        "navigation": "Navigacija",
        "next": "Sekantis",
        "nextPageLabel": "Sekantis puslapis",
        "nullLabel": "Nepasirinkta",
        "otpLabel": "Prašome įvesti vienkartinį slaptažodžio simoblį (-ius) {0}",
        "pageLabel": "Puslapis {page}",
        "passwordHide": "Nerodyti slaptažodžio",
        "passwordShow": "Rodyti slaptažodį",
        "previous": "Ankstesnis",
        "previousPageLabel": "Ankstesnis puslapis",
        "removeLabel": "Pašalinti",
        "rotateLeft": "Pasukti į kairę",
        "rotateRight": "Pasukti į dešinę",
        "rowsPerPageLabel": "Eilučių puslapyje",
        "saveEdit": "Išsaugoti redaguotą",
        "scrollTop": "Slinkti į viršų",
        "selectAll": "Pasirinkti visi elementai",
        "selectLabel": "Pasirinkti",
        "selectRow": "Eilutė pasirinkta",
        "showFilterMenu": "Rodyti filtro parinktis",
        "slide": "Slinkti",
        "slideNumber": "{slideNumber}",
        "star": "1 žvaigždė",
        "stars": "{star} žvaigždės",
        "trueLabel": "True",
        "unselectAll": "Nepasirinkta elementų",
        "unselectLabel": "Nuimti pasirinkimą",
        "unselectRow": "Eilutė nepasirinkta",
        "zoomImage": "Priartinti paveikslą",
        "zoomIn": "Priartinti",
        "zoomOut": "Atitolinti"
    }
  }
}