import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, interval, startWith, switchMap } from 'rxjs';
import { RestBaseService } from "../../../shared/services/rest-base.service";
import { InspectionGeolocationCreateDto } from "../../../services/models/geolocation-create-dto.model";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InspectionGeolocationService extends RestBaseService implements OnDestroy {

  intervalSubscription: Subscription | undefined;
  geolocationIntervalInSeconds = 45;

  ngOnDestroy(): void {
    this.intervalSubscription?.unsubscribe();
  }

  startGeolocation(inspectionId: number) {
    this.intervalSubscription = interval(this.geolocationIntervalInSeconds * 1000).pipe(
      startWith(0),
      switchMap(() => this.getCurrentPosition())
    ).subscribe({
      next: (position) => {
        this.create({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          inspectionId
        } as InspectionGeolocationCreateDto).subscribe();
      },
      error: (error) => {
        console.error('Error getting geolocation:', error);
      },
    });
  }

  private create(
    createDto: InspectionGeolocationCreateDto
  ): Observable<void> {
    return this.CreateAsync<void>(
      createDto,
      environment.api.inspectionGeolocationApiUrl
    );
  }

  private getCurrentPosition(): Observable<any> {
    return new Observable((observer) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      } else {
        observer.error('Geolocation is not available in this browser.');
      }
    });
  }
}