import { Component, OnChanges, OnInit, SimpleChanges, input, output, inject } from '@angular/core';
import { EventListFilterDto } from 'src/app/modules/control-object/models/event-list-filter-dto';
import { ControlObjectEventListItemResponseDto } from 'src/app/modules/control-object/models/event-list-item-response-dto.model';
import { InspectionKind, InspectionKindLabelPipe } from '../enums/inspection-kind.enum';
import { PagedResultDto } from '../models/paging-sorting';
import { ListService } from '../services/list.service';
import { ControlObjectEventService } from 'src/app/modules/control-object/services/control-object-event.service';
import { UserAuthorizationService } from 'src/app/modules/user/user-authorization.service';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { AuthorizedButtonComponent } from '../authorized-button/authorized-button.component';
import { TablePagingComponent } from '../table-paging/table-paging.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { InspectionTypeLabelPipe } from '../enums/inspection-type.enum';
import { DateMonthLabelPipe } from '../enums/date-month.enum';

@Component({
    selector: 'app-events-table',
    templateUrl: './events-table.component.html',
    styleUrls: ['./events-table.component.less'],
    providers: [ListService],
    imports: [FormsModule, AuthorizedButtonComponent, TablePagingComponent, SpinnerComponent, AsyncPipe, InspectionKindLabelPipe, InspectionTypeLabelPipe, DateMonthLabelPipe]
})
export class EventsTableComponent implements OnInit, OnChanges {
  private controlObjectEventService = inject(ControlObjectEventService);
  readonly list = inject<ListService<EventListFilterDto>>(ListService);
  userAuthService = inject(UserAuthorizationService);

  
  readonly resourceId = input<number>(0);

  readonly inspectionKind = input<InspectionKind>();

  readonly rowCreateActionButtonTitle = input<string>('');

  readonly rowCreateActionButtonPermitted = input(false);

  readonly rowViewActionButtonTitle = input<string>('');

  readonly noRecordsTitle = input<string>('');

  readonly title = input<string>('');

  readonly refreshListTime = input<number>();

  readonly onRowActionButtonClick = output<ControlObjectEventListItemResponseDto>();

  readonly onEditEventClick = output<ControlObjectEventListItemResponseDto>();

  readonly onDeleteEventClick = output<ControlObjectEventListItemResponseDto>();

  rows: PagedResultDto<ControlObjectEventListItemResponseDto> = {};

  isPlanned = false;

  ngOnInit(): void {
    this.getControlObjectData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshListTime'] && !changes['refreshListTime'].firstChange) {
      this.getControlObjectData();
    }
  }

  getControlObjectData(searchDto: EventListFilterDto = {}) {
    this.isPlanned = this.inspectionKind() === InspectionKind.Scheduled;
    searchDto.isPlanned = this.isPlanned;
    
    const controlObjectsStreamCreator = (query: any) =>
      this.controlObjectEventService.getFilteredList(this.resourceId(), {
        ...query,
        ...searchDto,
      });

    this.list
      .hookToQuery(controlObjectsStreamCreator)
      .subscribe((response) => {
        this.rows = response;
      });
  }

  rowActionClickHandler(row: ControlObjectEventListItemResponseDto) {
   this.onRowActionButtonClick.emit(row);
  }

  editEvent(row: ControlObjectEventListItemResponseDto) {
    this.onEditEventClick.emit(row);
  }

  deleteEvent(row: ControlObjectEventListItemResponseDto) {
    this.onDeleteEventClick.emit(row);
  }
}
