import { Pipe, PipeTransform, inject } from "@angular/core";
import {DatePipe} from "@angular/common";

@Pipe({ name: 'dateLt' })
export class DateLtPipe implements PipeTransform {
  private datePipe = inject(DatePipe);


  transform(value?: any, noHours?: boolean): string {
    const result = this.datePipe.transform(value, noHours ? 'YYYY-MM-dd' : 'YYYY-MM-dd HH:mm')
    return result || '';
  }
}
