/// <reference types="@angular/localize" />

import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { environment } from './environments/environment';
import { HistoryService } from './app/services/history.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './app/auth/interceptors/auth.interceptor';
import { ErrorInterceptor } from './app/auth/interceptors/error.interceptor';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { LayoutModule } from './app/layout/layout.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import localeLt from '@angular/common/locales/lt';
import { ROUTES_CONFIG } from './app/app.routes';
import { AuthService } from './app/services/auth.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

registerLocaleData(localeLt, 'lt');

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, LayoutModule, FontAwesomeModule, ReactiveFormsModule, FormsModule),
        HistoryService,
        AuthService,
        {
            provide: LOCALE_ID,
            useValue: 'lt'
        },
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        provideHttpClient(withInterceptors([AuthInterceptor, ErrorInterceptor])),
        provideRouter(ROUTES_CONFIG/*, withComponentInputBinding()*/),
        provideAnimationsAsync(),
        providePrimeNG({
          theme: {
            preset: Aura,
            options: {
              prefix: 'p',
              darkModeSelector: 'none',
              cssLayer: false
            }
          }
        })
    ]
})
  .catch(err => console.error(err));
