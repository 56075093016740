import { Component, ElementRef, HostListener, input, model, output, viewChild, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationService } from 'src/app/modules/notifications/services/notification.service';
import { FileDescriptorService } from 'src/app/services/file-descriptor.service';
import { FileAttachmentDto } from '../models/file-attachment-dto.model';
import { faCamera, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-multiple-files-upload',
    templateUrl: './multiple-files-upload.component.html',
    styleUrls: ['./multiple-files-upload.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultipleFilesUploadComponent,
            multi: true
        }
    ],
    imports: [NgClass, FaIconComponent]
})
export class MultipleFilesUploadComponent implements ControlValueAccessor {
  private host = inject<ElementRef<HTMLInputElement>>(ElementRef);
  private fileService = inject(FileDescriptorService);
  private notificationService = inject(NotificationService);


  private onTouched!: Function;

  private onChange!: Function;

  public files: FileAttachmentDto[] = [];

  public type = input.required<"files" | "images">();

  cameraIcon = faCamera;

  deleteIcon = faTrash;

  downloadIcon = faDownload;

  readonly onRemove = output<number>();

  readonly fileSelectElement = viewChild<ElementRef<any>>('fileSelectElement');

  readonly isDisabled = model(false);

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const newFiles = event && Array.from(event);

    const uniqueNewFiles = newFiles.filter((newFile: File) => {
      return !this.files.some(f => f.attachedFile?.name === newFile.name || f.fileName === newFile.name);
    });

    const uniqueFileAttachments: FileAttachmentDto[] = uniqueNewFiles.map(unf => {
      return {
        attachedFile: unf
      }
    });

    this.onChange(uniqueFileAttachments);

    uniqueFileAttachments.forEach(ufa => this.files.push(ufa));
  }

  writeValue( value: FileAttachmentDto[] = [] ) {
    this.onSelectedFileRemove();
    if (value.length > 0) {
      this.files = value;
    }
  }

  onSelectedFileRemove() {
    this.host.nativeElement.value = '';
    this.files = [];
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  removeFileFromList(event: Event, i: number) {
    if (this.isDisabled() === false) {
      this.onRemove.emit(i);
      event.preventDefault();
      this.files?.splice(i, 1);
      const fileSelectElement = this.fileSelectElement();
      if (fileSelectElement && fileSelectElement.nativeElement) {
        fileSelectElement.nativeElement.value = '';
      }
    }
  }

  download(event: Event, id?: number, fileName?: string) {
    event.preventDefault();
    if (id && fileName)
    this.fileService.download(id, fileName);
  }
}
