@if(link() == true){
  @if (haveRole()) {
    <a [ngClass]="style()" (click)="clickButton()"
      [attr.aria-disabled]="!haveRole()" tabindex="{{haveRole() ? '0' : '-1'}}">
      {{name()}}
    </a>
  }
}
@else {
@if (haveRole()) {
  <button [attr.type]="type()" [attr.value]="value()" [ngClass]="style()" (click)="clickButton()">
    {{name()}}
  </button>
}
}
