import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';

@Component({
    selector: 'app-page-layout',
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.less'],
    imports: [RouterOutlet, FooterComponent]
})
export class PageLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
