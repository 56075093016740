import { inject, Injectable } from '@angular/core';
import { Observable, fromEvent, merge, Subject, timer, Subscription } from 'rxjs';
import { IdleSessionBoxComponent } from '../auth/idle-session-box/idle-session-box.component';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idle: Observable<any> = new Observable();
  private timer: Subscription = new Subscription();
  private timeOutMilliSeconds = 1000;
  private idleSubscription: Subscription = new Subscription();
  public expired: Subject<boolean> = new Subject<boolean>();
  private dialogService = inject(DialogService);

  public isDialogActive: boolean = false;
  public startWatching(timeOutSeconds: number): Observable<any> {
    this.idle = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );
    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.idleSubscription = this.idle.subscribe((res) => {
      this.resetTimer();
    });
    this.startTimer();
    return this.expired;
  }
  private startTimer(): void {
   this.timer = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe((res) => {
      this.expired.next(true);
    });
  }
  public resetTimer(): void {
    this.timer.unsubscribe();
    this.startTimer();
  }
  public stopTimer() {
    this.timer.unsubscribe();
    this.idleSubscription.unsubscribe();
  }

  public openDialog() {
    if(!this.isDialogActive){
      this.isDialogActive = true;
      const dialogRef = this.dialogService.open(IdleSessionBoxComponent, {
        width: '650px',
      });

      dialogRef.onClose.subscribe(() => {
        this.isDialogActive = false;
      });
  }
  }
}
