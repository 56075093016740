import { Routes } from "@angular/router";
import { requirePermission, requireRole } from "./auth/auth/auth.guard";
import { userPermissions, userRoles } from "./modules/user/models/user-permissions";
import { DatePipe } from "@angular/common";
import { DateLtPipe } from "./shared/date-pipes/date-lt.pipe";
import { InspectionGeolocationService } from "./modules/inspection/services/inspection-geolocation.service";

export const ROUTES_CONFIG: Routes = [
  {
    path: '',
    loadComponent: () => 
      import('./modules/subject/subject.component').then(m => m.SubjectComponent),
    canActivate: [
      requirePermission(userPermissions.VIEW_SUBJECT_LIST)
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./auth/auth/auth.component').then(m => m.AuthComponent),
  },
  {
    path: 'subject',
    loadChildren: () => 
      import('./modules/subject/subject.routes').then((m) => m.SUBJECT_ROUTES)
  },
  {
    path: 'control-object',
    loadChildren: () => 
      import('./modules/control-object/control-object.routes').then((m) => m.CONTROL_OBJECT_ROUTES)
  },
  {
    path: 'plan',
    loadChildren: () => 
      import('./modules/plan/plan.routes').then((m) => m.PLAN_ROUTES),
  },
  {
    path: 'order',
    loadChildren: () => 
      import('./modules/order/order.routes').then((m) => m.ORDER_ROUTES),
  },
  {
    path: 'inspection',
    loadChildren: () => 
      import('./modules/inspection/inspection.routes').then((m) => m.INSPECTION_ROUTES),
    providers: [DateLtPipe, DatePipe, InspectionGeolocationService]
  },
  {
    path: 'report',
    loadChildren: () => 
      import('./modules/reports/reports.routes').then((m) => m.REPORTS_ROUTES),
    providers: [DateLtPipe, DatePipe]
  },
  {
    path: 'admin',
    loadChildren: () => 
      import('./modules/admin/admin.routes').then((m) => m.ADMIN_ROUTES),
    canActivateChild: [
      requireRole(userRoles.ADMINISTRATOR)
    ],
    providers: [DatePipe],
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('./auth/access-denied/access-denied.component').then(
        (m) => m.AccessDeniedComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./auth/http-not-found404/http-not-found404.component').then(
        (m) => m.HttpNotFound404Component
      ),
  },
];
